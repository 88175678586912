<template>
	<div>

		<h1 class="text-2xl font-bold mb-5">Recent Activity</h1>

		<div class="border border-solid border-blue-200 rounded overflow-hidden">
			<datatable :ajax="true" :url="`${$baseurl}/staff/activities/${user.id}`" :ajax-headers="headers" :columns="columns" ref="table" />
		</div>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				searchQuery: '',
				company: null,
				loading: false,
				activity: [],
				columns: [
					{
						name: 'description',
						th: 'Description',
						render: activity => this.getActivityDescription(activity)
					},
					{
						name: 'created_at',
						th: 'Date'
					},
				]
			}
		},
		beforeMount() {
			
		},
		methods: {
			
		}
	}
</script>